/* ==========================================================================
   Buttons
   ========================================================================== */

.btn {
  display: inline-block;
  padding: 0.5em 1em;
  border: 0;
  border-radius: $border-radius;
  background-color: $accent-color;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: tint($accent-color, 20%);
    color: #fff;
  }

  &:focus {
    background-color: shade($accent-color, 20%);
  }

  .icon {
    margin-right: 0.5em;
  }

  /* block button fills width of parent container */
  &--block {
    display: block;
    width: 100%;

    + .btn--block {
      margin-top: 1rem;
    }
  }
}
