/* ==========================================================================
   Alignment
   ========================================================================== */

.align-baseline { vertical-align: baseline !important; } /* Browser default */
.align-top { vertical-align: top !important; }
.align-middle { vertical-align: middle !important; }
.align-bottom { vertical-align: bottom !important; }
.align-text-bottom { vertical-align: text-bottom !important; }
.align-text-top { vertical-align: text-top !important; }

/*
   Image Alignment
   ========================================================================== */

.align-center,
div.align-center,
a img.align-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

figure.align-center {

  img {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  figcaption {
    text-align: center;
  }
}

.align-right,
a img.align-right {
  margin-bottom: 1rem;
  margin-left: 1rem;
  float: right;
}

.align-left,
a img.align-left {
  margin-right: 1rem;
  margin-bottom: 1rem;
  float: left;
}

/*
   Sidebar Pusher
   ========================================================================== */

.is--pushed {
  transform: translateX(1 * $sidebar-width);
  transform-origin: right;

  @include breakpoint($large) {
    transform: translateX(1.5 * $sidebar-width);
  }
}
