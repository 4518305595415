/* ==========================================================================
   Contact lists
   ========================================================================== */

.contact-list {
  @include list-unstyled;

  .icon {
    margin-right: 0.25em;
  }
}
