/* ==========================================================================
   Night theme skin
   ========================================================================== */

/* Colors */
$base-color: #252a34 !default;
$text-color: #eaeaea !default;
$accent-color: #00adb5 !default;
$background-color: #252a34 !default;
$code-background-color: #fff !default;
$border-color: rgba($text-color, 0.5) !default;
$overlay-color: #fff !default;
